import React from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageFooter from "../components/PageFooter"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <>
    <SEO title={"Work | Food Companion"} slug={"food-companion"} />
    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h1>Food Companion - Personal Nutrition Assistant, Mobile App</h1>
            <br />
            <p>Case Study</p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="case-study-content">
      <Container
        className="imagery-content "
        style={{
          background: "#b3d271",
          marginBottom: 30,
        }}
        fluid
        tag="section"
      >
        <Container>
          <Row className="mb-5 pb-5">
            <Col xs="12" sm="6">
              <StaticImage
                src="../images/foodcompanion/foodcomp.png"
                alt="web_1"
                width={1600}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Col>
            <Col xs="12" sm="6" className="mt-5 pt-5">
              <StaticImage
                src="../images/foodcompanion/foodcomp_2.png"
                alt="web_1"
                width={1600}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="article-content" tag="section" fluid>
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="6" className="p-0 section-text float-left">
                <h3>01 Challenge</h3>
                <p className="gray">
                  Food Companion emerged as an ambitious initiative from
                  Biobalance GmbH, a German start-up dedicated to promoting
                  balanced diets and healthy nutrition.
                  <br />
                  <br />
                  The objective was to craft a personal assistant app that would
                  revolutionize nutritional habits, fostering a commitment to
                  healthy eating. This vision was realized through a
                  comprehensive suite of features, including trackers,
                  challenges, micro-assistant tools, and curated content.
                  <br />
                  <br />
                  In the pipeline, the company envisioned the implementation of
                  an AI-based assessment and recommendation system, poised to
                  deliver an even more personalized experience to users.
                  <br />
                  <br />
                  Our responsibility was to conceive and develop an initial
                  product version, employing a Minimum Viable Product (MVP)
                  approach.
                </p>
              </Col>
              <Col
                sm={{ size: 4, offset: 2 }}
                className="p-0 section-text float-left"
              >
                <h4>Scope</h4>
                <p className="gray">
                  Product design / MVP / React Native / Backend / Project
                  Management
                </p>
                <h4>Date</h4>
                <p className="gray">2021 - 2022</p>
                <h4>Client & location</h4>
                <p className="gray">
                  Biobalance GmbH / Frankfurt am Main, Germany
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mobile-screens-container">
        <Row>
          <Col xs="12" sm="4">
            <Container
              className="imagery-content mobile-imagery"
              style={{ background: "" }}
              fluid
              tag="section"
            >
              <StaticImage
                src="../images/foodcompanion/mobile_2.png"
                alt="mobile_1"
                width={1200}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Container>
          </Col>
          <Col xs="12" sm="4">
            <Container
              className="imagery-content mobile-imagery"
              style={{ background: "" }}
              fluid
              tag="section"
            >
              <StaticImage
                src="../images/foodcompanion/mobile_3.png"
                alt="mobile_3"
                width={1200}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Container>
          </Col>
          <Col xs="12" sm="4">
            <Container
              className="imagery-content mobile-imagery"
              style={{ background: "" }}
              fluid
              tag="section"
            >
              <StaticImage
                src="../images/foodcompanion/mobile_1.png"
                alt="mobile_1"
                width={1200}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Container>
          </Col>
        </Row>
      </Container>{" "}
      <Container
        className="article-content"
        tag="section"
        fluid
        style={{ background: "transparent" }}
      >
        <Container>
          <Row className="pt-5 mt-5 mb-5 pb-3">
            <Col sm="4" className="p-0 section-text float-left">
              <div className="pr-4 pl-4">
                <h4>Challenges & Tasks</h4>
                <p>
                  Nutrition challenges and exercises designed to help overcome
                  unhealthy eating habits.
                </p>
                <br />
                <h4>Notifications</h4>
                <p>
                  To help you stay on track and never miss your challenge or a
                  task during your busy day.
                </p>
              </div>
            </Col>
            <Col sm="4" className="p-0 section-text float-left">
              <div className="pr-4 pl-4">
                <h4>Calorie Intake Tracker</h4>
                <p>
                  Track your calorie intake from both food and beverages to make
                  informed choices.
                </p>
                <br />
                <h4>Seamless Onboarding</h4>
                <p>
                  Deep linking to enhance onboarding and to point users directly
                  to the content they are interested in.
                </p>
              </div>
            </Col>
            <Col sm="4" className="p-0 section-text float-left">
              <div className="pr-4 pl-4">
                <h4>Curated Content</h4>
                <p>
                  Educational content on nutrition through a collection of
                  articles, videos, and other resource.
                </p>
                <br />
                <h4>Personalized Experience</h4>
                <p>
                  Each challenge starts with goal setting and benchmarking. You
                  always do you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "" }}
        fluid
        tag="section"
      >
        <div className="circles">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <Container>
          <Row className="mb-5 pb-5">
            <Col sm="6">
              <StaticImage
                src="../images/foodcompanion/foodcomp_3.png"
                alt="web_1"
                width={1600}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Col>
            <Col sm="6" className="mt-5 pt-5">
              <StaticImage
                src="../images/foodcompanion/foodcomp_4.png"
                alt="web_1"
                width={1600}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Col>
            <Col sm="3" className="mt-5 pt-5"></Col>
            <Col sm="6" className="mt-5 pt-5">
              <StaticImage
                src="../images/foodcompanion/foodcomp_5.png"
                alt="web_1"
                width={1600}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row align="center" className="mt-5 pt-5 mb-5">
            <Col sm="4">
              <h2>4</h2>
              <p>Team Members</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>8 Mos.</h2>
              <p>Duration</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>Dec. '21</h2>
              <p>Launch Date</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
    <WipeScreen />
    <PageFooter project="biologica" />
  </>
)

SecondPage.Layout = Layout
export default SecondPage
